import React from "react";
import { Link } from "gatsby";
import Logo from "./Logo";

function Header() {

  return (
    <>
      <div>
        <header className="header" id="header">
          <nav className="d-flex justify-content-between align-items-stretch header-nav container-fluid header-width">
            <Link
              className="d-flex justify-content-between align-items-center"
              to="/"
            >
              <Logo width="240" />
            </Link>
          </nav>
        </header>
      </div>
    </>
  );
}

export default Header;
