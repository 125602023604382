import React, { useEffect } from "react";
import Header from "./Header";
import "../../css/bootstrap.css";
import "../../css/common.css";
import "../../css/modal.css";
import Footer from "./Footer";
import favicon from "../../images/favicon.png";
import Helmet from "react-helmet";

function Layout({ children, chatCodeId }) {
  useEffect(() => {
    if (!document.getElementById("lp_cwc_xqzyihjdskw")) {
      let chatDiv = document.getElementById("livprop_chatbox");
      if (chatDiv) {
        document.body.removeChild(chatDiv);
        if (
          chatDiv.nextElementSibling &&
          chatDiv.nextElementSibling.tagName === "script"
        ) {
          document.body.removeChild(chatDiv.nextElementSibling);
        }
      }
      const s1 = document.createElement("script");
      s1.id = "lp_cwc_xqzyihjdskw";
      s1.src = `https://cwc.livserv.in/chat.js?lid=${
        chatCodeId ? chatCodeId : 12981
      }`;
      const s2 = document.createElement("script");
      s2.id = "chatScript2";
      s2.src = `https://cw1.livserv.in?did=${
        chatCodeId ? chatCodeId : 12981
      }&pid=1`;
      document.body.appendChild(s1);
      document.body.appendChild(s2);

      return () => {
        document.body.removeChild(s1);
        document.body.removeChild(s2);
      };
    }
  }, [chatCodeId]);

  return (
    <>
      <Header />
      <Helmet>
        <link rel="icon" href={favicon} />
        <title>Baashyaam Group - Luxury Apartments in Chennai</title>
      </Helmet>
      <main className="page-container">{children}</main>
      <Footer />
    </>
  );
}

export default Layout;
